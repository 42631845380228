<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">User profile</span>
        </v-card-title>
        <v-card-text>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Name</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="secondary--text subtitle-2">{{ userInfo.given_name }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Surname</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="secondary--text subtitle-2">{{ userInfo.family_name }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Username</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="secondary--text subtitle-2">{{ userInfo.username }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Email</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="secondary--text subtitle-2">{{ userInfo.email }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>User status</v-subheader>
                </v-col>
                <v-col cols="8">
                    <span class="secondary--text subtitle-2">{{ userInfo.is_active ? 'Active' : 'Inactive' }}</span>
                </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Newsletter</v-subheader>
                </v-col>
                <v-col cols="8">
                    <div class="d-flex">
                        <v-checkbox v-model="newsletter" :disabled="fetchingUserInfo" label="Signed up for newsletter"></v-checkbox>
                    </div>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Analytics</v-subheader>
                </v-col>
                <v-col cols="8">
                    <div class="d-flex">
                        <v-checkbox v-model="analyticsOptIn" :disabled="fetchingUserInfo" label="Allow use of analytics data" @click="confirm"></v-checkbox>
                    </div>
                    <p v-if="!isProduction()">This is non production so analytics is always off.</p>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset="4">
                    <v-btn color="primary" @click="save">Save</v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-4"></v-divider>
            <v-row v-if="userInfo.is_sysadmin" align="center">
                <v-col cols="4">
                    <v-subheader>System Administrator</v-subheader>
                </v-col>
                <v-col cols="8">
                    <v-btn color="primary" :to="{ name: 'admin-overview' }" depressed>manage</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="!userInfo.is_sysadmin" align="center">
                <v-col cols="4">
                    <v-subheader>Delete account</v-subheader>
                </v-col>
                <v-col cols="8">
                    <AccountDeleteDialog></AccountDeleteDialog>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import eventBus from '@/components/EventBus'
import { analyticsMixin } from '@/mixins/analytics'
import { isProduction } from '@/helpers'

const AccountDeleteDialog = () => import('@/components/TheUserDeleteAccount')

export default {
    mixins: [analyticsMixin],
    components: { AccountDeleteDialog },
    data() {
        return {
            newsletter: true,
            analyticsOptIn: false
        }
    },
    methods: {
        async save() {
            const analyticsChanged = this.userMetadata.analytics_opt_in !== this.analyticsOptIn
            try {
                await this.$store.commit('setUserMetadata', { unsubscribed_from_newsletter: !this.newsletter, analytics_opt_in: this.analyticsOptIn })
                this.$store.dispatch('showSnackBar', {
                    snackBarText: analyticsChanged ? `The page will be reloaded so your changes can take effect` : `User settings saved.`,
                    snackBarTimeout: analyticsChanged ? 4000 : 2000,
                    snackBarIcon: 'check_circle'
                })
                if (analyticsChanged) {
                    window.setTimeout(() => window.location.reload(), 4000)
                }
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: `Could not save user settings!`,
                    snackBarTimeout: 2000,
                    snackBarIcon: 'error'
                })
            }
        },
        confirm() {
            if (this.analyticsOptIn === false) {
                this.$store.dispatch('showGlobalDialog', {
                    dialogText:
                        'Site analytics data helps us to identify potential and materialized issues faster. If you opt out from analytics, it may take longer to resolve your future support requests.',
                    dialogAction: ['cancel', 'accept'],
                    dialogTitle: 'Confirm opting out'
                })
            }
        },
        isProduction() {
            return isProduction()
        }
    },
    computed: {
        ...mapState(['userInfo', 'fetchingUserInfo', 'userMetadata'])
    },
    watch: {
        userMetadata(data) {
            this.newsletter = !this.userMetadata?.unsubscribed_from_newsletter
            this.analyticsOptIn = this.isAnalyticsEnabled
        }
    },
    created() {
        this.analyticsOptIn = this.isAnalyticsEnabled
        this.newsletter = !this.userMetadata?.unsubscribed_from_newsletter
        eventBus.$on('global-dialog-decline', () => {
            this.analyticsOptIn = true
        })
    }
}
</script>
